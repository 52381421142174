<template>
  <v-container>
    <v-dialog ref="AddVisitMemoDialog" v-model="AddVisitMemoDialog" width="500">
      <AddVisitMemoDialog
        :visit-memo-item="visitMemoItem"
        :customer-id="customer_id"
        :customer="customer"
        @close-dialog="AddVisitMemoDialog = false"
      ></AddVisitMemoDialog>
    </v-dialog>
    <v-row>
      <v-col cols="12">
        <Table
          :headers="headers"
          :items="allVisitMemos"
          :total-records="visitMemosPaginationData.records_total || 0"
          :number-of-pages="visitMemosPaginationData.total_pages || 1"
          :footer="false"
          class="font-weight-medium mb-4"
          @pagination:update="updateCustomerVisitMemos"
          @click:row="editVisitMemo"
        >
          <template v-slot:item.visited_date="{ item }">
            {{getDateFormat(item.visited_date, 'ja')}}
          </template>
          <template v-slot:item.user_id="{ item }">
            {{ visitUsers(item) }}
          </template>
          <template v-slot:item.memo="{ item }">
            <span class="memo">{{ item.memo }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn @click="editVisitMemo(item)" fab x-small color="primary">
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <template v-slot:top>
            <div class="mb-6">
              <v-row align="end">
                <v-col cols="auto" class="text-heading-1">
                  訪問メモ一覧
                </v-col>
                <v-col cols="auto" class="ml-4 text-heading-3">
                  全 ({{ customerVisitMemosCount }}) 件あります。
                </v-col>
                <v-col cols="auto" class="flex-grow-1 text-right">
                  <v-btn
                    height="28"
                    class="btn-new-task"
                    color="#CFD3FE"
                    depressed
                    min-width="100"
                    @click="addVisitMemo"
                  >
                    <v-icon left> mdi-plus </v-icon>
                    訪問メモ
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </template>
        </Table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
import Table from '@/components/admin/partials/Table/Table'
import AddVisitMemoDialog from '@/components/admin/partials/Customer/View/VisitMemo/Dialog'
export default {
  name: 'VisitMemo',
  props: {
    customer_id: {
      type: [Number, String],
      required: true
    },
    customer: {
      type: Object,
      required: true
    }
  },
  components: { AddVisitMemoDialog, Table },
  data() {
    return {
      AddVisitMemoDialog: false,
      visitMemoItem: {},
      page: 1,
      pageCount: 5,
      headers: [
        { text: '訪問日', align: 'center', value: 'visited_date', width: 100 },
        {
          text: '訪問者',
          align: 'center',
          value: 'user_id',
          width: 200,
          sortable: false
        },
        {
          text: '訪問メモ',
          value: 'memo',
          width: 300,
          sortable: false
        },
        {
          text: '',
          align: 'center',
          value: 'actions',
          width: 50,
          sortable: false
        }
      ],
      users: []
    }
  },
  computed: {
    ...mapGetters(['user', 'visitMemosPagination', 'customerVisitMemosCount','allVisitMemos']),
    visitMemosPaginationData() {
      return this.visitMemosPagination
    },
    visitedUsers() {
      return this.users?.map(visitedUsers => {
        return { value: visitedUsers.id, text: visitedUsers.last_name }
      })
    }
  },
  async mounted() {
    await this.$store.dispatch('DROPDOWN_USER_LIST', { with_trashed: true}).then(res => {
     this.users = [...res.data.data.users]
    })
    this.getDataFromApi()
  },
  methods: {
    getDateFormat(date, ln) {
      if (ln == 'ja') {
        return dayjs(date).format(`YYYY年MM月DD日`)
      } else {
        return dayjs(date).format(`YYYY/MM/DD H:mm`)
      }
    },
    addVisitMemo() {
      this.AddVisitMemoDialog = true
      this.visitMemoItem = {}
    },
    editVisitMemo: function(item) {
      this.AddVisitMemoDialog = true
      this.visitMemoItem = item
    },
    getDataFromApi() {
      this.updateCustomerVisitMemos(1)
    },
    updateCustomerVisitMemos(page) {
      this.page = page
      let param = {
        customer_id: this.$route.params?.customer_id,
        paginate: 10,
        page: page
      }
      this.$store.dispatch('VISIT_MEMO_GET_ALL', param).then(() => {
        this.loading = false
      })
    },
    visitUsers(item) {
      let visitedUserTxt = ''
      if (item.user_id && item.user_id != "null") {
        this.visitedUsers.map(function(value, key) {
          if (JSON.parse(item.user_id).includes(value.value)) {
            if (visitedUserTxt == '') {
              visitedUserTxt = value.text
            } else {
              visitedUserTxt = visitedUserTxt + ', ' + value.text
            }
          }
        })
      }
      return visitedUserTxt
    }
  }
}
</script>

<style lang="scss" src="./VisitMemo.scss" scoped></style>
