var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-dialog', {
    ref: "AddVisitMemoDialog",
    attrs: {
      "width": "500"
    },
    model: {
      value: _vm.AddVisitMemoDialog,
      callback: function callback($$v) {
        _vm.AddVisitMemoDialog = $$v;
      },
      expression: "AddVisitMemoDialog"
    }
  }, [_c('AddVisitMemoDialog', {
    attrs: {
      "visit-memo-item": _vm.visitMemoItem,
      "customer-id": _vm.customer_id,
      "customer": _vm.customer
    },
    on: {
      "close-dialog": function closeDialog($event) {
        _vm.AddVisitMemoDialog = false;
      }
    }
  })], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('Table', {
    staticClass: "font-weight-medium mb-4",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.allVisitMemos,
      "total-records": _vm.visitMemosPaginationData.records_total || 0,
      "number-of-pages": _vm.visitMemosPaginationData.total_pages || 1,
      "footer": false
    },
    on: {
      "pagination:update": _vm.updateCustomerVisitMemos,
      "click:row": _vm.editVisitMemo
    },
    scopedSlots: _vm._u([{
      key: "item.visited_date",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm.getDateFormat(item.visited_date, 'ja')) + " ")];
      }
    }, {
      key: "item.user_id",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.visitUsers(item)) + " ")];
      }
    }, {
      key: "item.memo",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('span', {
          staticClass: "memo"
        }, [_vm._v(_vm._s(item.memo))])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('v-btn', {
          attrs: {
            "fab": "",
            "x-small": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.editVisitMemo(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-pencil")])], 1)];
      }
    }, {
      key: "top",
      fn: function fn() {
        return [_c('div', {
          staticClass: "mb-6"
        }, [_c('v-row', {
          attrs: {
            "align": "end"
          }
        }, [_c('v-col', {
          staticClass: "text-heading-1",
          attrs: {
            "cols": "auto"
          }
        }, [_vm._v(" 訪問メモ一覧 ")]), _c('v-col', {
          staticClass: "ml-4 text-heading-3",
          attrs: {
            "cols": "auto"
          }
        }, [_vm._v(" 全 (" + _vm._s(_vm.customerVisitMemosCount) + ") 件あります。 ")]), _c('v-col', {
          staticClass: "flex-grow-1 text-right",
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          staticClass: "btn-new-task",
          attrs: {
            "height": "28",
            "color": "#CFD3FE",
            "depressed": "",
            "min-width": "100"
          },
          on: {
            "click": _vm.addVisitMemo
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v(" mdi-plus ")]), _vm._v(" 訪問メモ ")], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }