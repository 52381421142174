var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-text', [_c('v-container', [_c('v-row', {
    staticClass: "py-4"
  }, [_c('v-col', {
    staticClass: "text-center text-heading-1",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" メモ管理 "), _c('div', [_c('v-btn', {
    staticClass: "close-button",
    attrs: {
      "depressed": "",
      "fab": "",
      "color": "#E27C7C",
      "width": "20",
      "height": "20"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close-dialog');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "14"
    }
  }, [_vm._v("mdi-close")])], 1)], 1)])], 1), _c('div', {
    staticClass: "claim-form"
  }, [_c('validation-observer', {
    ref: "observer"
  }, [_c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.save.apply(null, arguments);
      }
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "form-header mb-2"
  }, [_vm._v("訪問日")]), _c('div', [_c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "nudge-right": 40,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('validation-provider', {
          attrs: {
            "name": "訪問日",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('v-text-field', _vm._g(_vm._b({
                attrs: {
                  "readonly": "",
                  "dense": "",
                  "hide-details": "",
                  "outlined": "",
                  "placeholder": "訪問日",
                  "error-messages": errors,
                  "error": errors.length !== 0
                },
                model: {
                  value: _vm.data.visited_date,
                  callback: function callback($$v) {
                    _vm.$set(_vm.data, "visited_date", $$v);
                  },
                  expression: "data.visited_date"
                }
              }, 'v-text-field', attrs, false), on))];
            }
          }], null, true)
        })];
      }
    }]),
    model: {
      value: _vm.menu,
      callback: function callback($$v) {
        _vm.menu = $$v;
      },
      expression: "menu"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "day-format": _vm.formatDate
    },
    on: {
      "input": function input($event) {
        _vm.menu = false;
      }
    },
    model: {
      value: _vm.data.visited_date,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "visited_date", $$v);
      },
      expression: "data.visited_date"
    }
  })], 1)], 1)]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "form-header mb-2"
  }, [_vm._v("対応者")]), _c('div', [_c('validation-provider', {
    attrs: {
      "name": "対応者",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('v-select', {
          staticClass: "form-text",
          attrs: {
            "outlined": "",
            "dense": "",
            "error-messages": errors,
            "error": errors.length !== 0,
            "items": _vm.employees,
            "hide-details": "auto",
            "placeholder": "未選択",
            "chips": "",
            "multiple": "",
            "clearable": "",
            "item-value": "id",
            "item-text": "full_name"
          },
          scopedSlots: _vm._u([{
            key: "selection",
            fn: function fn(_ref4) {
              var attrs = _ref4.attrs,
                  item = _ref4.item,
                  select = _ref4.select;
              return [_c('v-chip', _vm._b({
                staticClass: "mb-1",
                attrs: {
                  "small": "",
                  "close": ""
                },
                on: {
                  "click": select,
                  "click:close": function clickClose($event) {
                    return _vm.removeTag(item.id);
                  }
                }
              }, 'v-chip', attrs, false), [_c('span', [_vm._v(_vm._s(item.full_name))])])];
            }
          }], null, true),
          model: {
            value: _vm.data.user_id,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "user_id", $$v);
            },
            expression: "data.user_id"
          }
        })];
      }
    }])
  })], 1)]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "form-header mb-2"
  }, [_vm._v("訪問メモ")]), _c('div', [_c('validation-provider', {
    attrs: {
      "name": "訪問メモ",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('v-textarea', {
          staticClass: "form-text",
          attrs: {
            "dense": "",
            "outlined": "",
            "placeholder": "訪問メモ",
            "hide-details": "",
            "error-messages": errors,
            "error": errors.length !== 0,
            "maxlength": _vm.descriptionMaxLength
          },
          on: {
            "keyup": function keyup($event) {
              return _vm.desCount();
            }
          },
          model: {
            value: _vm.data.memo,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "memo", $$v);
            },
            expression: "data.memo"
          }
        })];
      }
    }])
  }), _c('div', {
    staticClass: "text-right mt-1"
  }, [_vm._v(" " + _vm._s(_vm.totalDescharacter ? _vm.totalDescharacter : _vm.data.memo.length) + " / " + _vm._s(_vm.descriptionMaxLength) + " 文字 ")])], 1)]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [this.visitMemoItem && this.visitMemoItem.id ? _c('v-btn', {
    attrs: {
      "color": "#AD4545",
      "text": ""
    },
    on: {
      "click": _vm.deleteTask
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("$trash")]), _vm._v("削除する")], 1) : _vm._e()], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "white--text",
    attrs: {
      "color": "#4F55A7",
      "width": "100",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("登録 ")])], 1)], 1)], 1)], 1)], 1)])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }